import {bindable, bindingMode, customElement} from "aurelia-framework"
import store from "../store/store"
import {swiftInfo} from "../store/api";

const {initiate, select} = swiftInfo
const {dispatch, getState} = store

@customElement("sio-bic-info")
export class BicInfo {
    @bindable({defaultBindingMode: bindingMode.toView}) value

    constructor() {
        this.unsubscribe = store.subscribe(this.update.bind(this))
    }

    dispose() {
        this.unsubscribe()
        this.result?.unsubscribe()
    }

    update() {
        const {isLoading, data = []} = select(this.value)(getState())
        this.loading = isLoading
        this.bank = data[0]
        this.city = data[1]
        this.country = data[2]
    }

    valueChanged() {
        this.result?.unsubscribe()
        this.result = this.value && "" !== this.value ? dispatch(initiate(this.value)) : undefined
    }
}
