import {bindable, LogManager, customElement} from 'aurelia-framework';

import "./colorpicker.less";

const logger = LogManager.getLogger('ColorPickerElement');

@customElement('sio-color-picker')

export class ColorPicker
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    hasBrandColorCheckboxChange(event)
    {
        if (this.value == null || this.value === "") {
            this.value = "#000000";
        } else {
            this.value = null;
        }
    }

    colorPickerChange(event)
    {
        const pickedValue = event.target.value;
        this.value = pickedValue;
    }
}
