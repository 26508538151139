import {inject, customElement, bindable} from "aurelia-framework";
import {Client} from "../../../api/client";
import {FormServiceFactory} from "../../../form/service/form-service-factory";
import $ from "jquery";
import * as _ from "lodash";

import "./editable-table.less";

@customElement("sio-editable-table")
@inject(
    Client,
    FormServiceFactory
)
export class EditableTable
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    loading = false;

    constructor(
        client,
        formServiceFactory
    ) {
        this.client = client;
        this.formServiceFactory = formServiceFactory;
    }

    async bind()
    {
        await this.configChanged();
    }

    async configChanged()
    {
        if (!this.config) {
            this._columns = [];
            return;
        }

        this._columns = this.config.entry.columns;

        await this.valueChanged();
    }

    async valueChanged()
    {
        if (this.value === this._value) {
            return;
        }

        this.loading = true;
        this._value = await this.normalizeValueInput(this.value);
        this.loading = false;

        this._editableRows = {};
        this._value.forEach(
            (item, index) => {
                const form = this.formServiceFactory.getFormService(
                    this.buildForm(),
                    item,
                    undefined
                );

                form.changeCallback = (field, formService) => {
                    this.formValueChanged(field, formService, item, index);
                };

                this._editableRows[index] = form;
            }
        );

        console.debug("mapping check", this);
    }

    formValueChanged(field, formService, item, index) {
        this._value[index] = formService.getValue();

        this.value = this.normalizeValueOutput(this.value, formService.getValue(), index);
    }

    async normalizeValueInput(value)
    {
        if (!value || value.length === 0) {
            return [];
        }

        if (!this.config ||
            !this.config.entry ||
            !this.config.entry.embeds || this.config.entry.embeds.length === 0
        ) {
            return value;
        }

        const normalizedValue = value.map(
            item => _.clone(item) // important! not deep!
        );

        const futures = this.config.entry.embeds.map(
            property => {
                const modelId = value[0][property].modelId;
                const ids = value.map(item => item[property].id);
                const conditions = { 'id': {'$in': ids} };

                return {
                    property,
                    value: this.client.get(modelId + "?" + $.param({conditions}))
                };
            }
        );
        const queryResult = await Promise.all(futures.map(future => future.value));
        const queryResultByProperty = futures.map((item, index) => ({
            property: item.property,
            items: queryResult[index].items
        }));
        queryResultByProperty.forEach((result, key) => {
            const itemsAsArr = result.items;
            const itemsAsMap = {};

            itemsAsArr.forEach(item => {
                itemsAsMap[item.id] = item;
            });

            result.items = itemsAsMap;
        });

        queryResultByProperty.forEach(result => {
            const property = result.property;
            const queriedItems = result.items;

            normalizedValue.forEach(item => {
                const itemId = item[property].id;
                item[property] = queriedItems[itemId];
            });
        });

        return normalizedValue;
    }

    normalizeValueOutput(value, updatedValue, index)
    {
        const editableColumns = this._columns.filter(
            column => column.editable
        );

        editableColumns.forEach(column => {
            value[index][column.property] = updatedValue[column.property];
        });

        return value;
    }

    buildForm()
    {
        const editableColumns = this._columns.filter(
            column => column.editable
        );

        const fields = editableColumns.map(
            column => Object.assign({}, column.formField, {label: undefined})
        );

        return {
            fields
        };
    }

    static normalizeCore2InitialValue(value)
    {
        if (!value || value.length === 0) {
            return value;
        }

        const normalized = value.map(
            item => {
                delete item.modelId;
                return item;
            }
        );

        return normalized;
    }
}
