import { bindable, computedFrom } from 'aurelia-framework';

export class TimeInputHours
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    hoursChoices()
    {
        const hours = [];
        for (let i = 0; i < 24; ++i) {
            hours.push({
                label: (i.toString()).padStart(2, "0") + ":00",
                value: (i.toString()).padStart(2, "0") + "00",
            });
        }

        return hours;
    }

    @computedFrom("config")
    get choiceConfig()
    {
        const choiceConfig = {
            property: this.config.property,
            translateChoiceLabel: false,
            choices: this.hoursChoices(),
            required: this.config.required,
            default: this.config.default,
            multiple: false,
        };

        return choiceConfig;
    }
}
