import { bindable } from 'aurelia-framework';

export class TimeInputMinutes
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    toInputTimeValue(value)
    {
        if (value == null || value === '') return null;
        return value.slice(0, 2) + ':' + value.slice(2);
    }

    fromInputTimeValue(value)
    {
        if (value == null || value === '') return null;
        return value.replace(':', '');
    }

    onChange(event)
    {
        const newValue = event.target.value;
        this.value = this.fromInputTimeValue(newValue);
    }
}
